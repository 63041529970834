"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
} from "react";

import { CustomerAPI } from "@api-clients/customer";
import { DiscountsAPI } from "@api-clients/discounts";
import { FreshMealPlanAPI } from "apps/website/api";
import { useAuth } from "@auth/client-sdk-react";
import { CapiClient } from "@api-clients/capi";

import { PublicConfig } from "../config/public";
import { FormsActionsAPI } from "../api/FormsActions";
import { Statistics } from "../api/Statistics";
import { CheckoutAPI } from "../api/Checkout";

export interface ApiClientsContext {
  Checkout: CheckoutAPI;
  FreshMealPlan: FreshMealPlanAPI;
  Customer: CustomerAPI;
  FormActions: FormsActionsAPI;
  Capi: CapiClient;
  Statistics: Statistics;
  Discounts: DiscountsAPI;
  tokenStatus: {
    accessToken: string | undefined;
    loggedIn: boolean | undefined;
  };
}

export const apiContext = createContext<ApiClientsContext>(undefined as unknown as ApiClientsContext);

// Site-wide context for customer data (subscriptions, cats, etc.)
export const KatkinCustomerApiProvider: FC<PropsWithChildren> = ({ children }) => {

  const { accessToken, loggedIn } = useAuth();

  const clients = useMemo(() => ({
    Checkout: new CheckoutAPI(PublicConfig.NEXT_PUBLIC_CHECKOUT_URL, accessToken),
    FreshMealPlan: new FreshMealPlanAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL, accessToken),
    FormActions: new FormsActionsAPI(PublicConfig.NEXT_PUBLIC_FORM_ACTIONS_URL, accessToken),
    Customer: new CustomerAPI(
      PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
      accessToken,
      { withCredentials: true },
    ),
    Discounts: new DiscountsAPI(
      PublicConfig.NEXT_PUBLIC_DISCOUNT_API_BASE_URL,
      accessToken,
    ),
    Capi: new CapiClient(
      PublicConfig.NEXT_PUBLIC_CAPI_BASE_URL,
      accessToken,
    ),
    Statistics: new Statistics(PublicConfig.NEXT_PUBLIC_STATISTICS_URL, accessToken),
    tokenStatus: {
      accessToken,
      loggedIn,
    },
  }), [ loggedIn, accessToken ]);

  return (
    <apiContext.Provider value={clients}>
      { children }
    </apiContext.Provider>
  );
};
